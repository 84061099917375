@import "../../../styles/_mixins.scss";


.menu-button-wrap {
  position: fixed;
  left: 11px;
  top: 100px;
  z-index: 11;
  will-change: transform;
  transition: transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);

  .menu-button {
    cursor: pointer;
    width: 48px;
    height: 48px;
    border: none;
    background: 0 0;
    will-change: transform;
    transition: transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);

    span {
      &:after,
      &:before {
        content: "";
        width: 2px;
        height: 48px;
        position: absolute;
        background-color: #111;
        border: 0.5px solid #b2b2b2;
        left: 50%;
        top: 50%;
        display: block;
        will-change: transform;
        transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &:before {
        transform: translate(-5px, -50%) scaleY(0.7) rotate(180deg);
      }

      &:after {
        transform: translate(5px, -50%) scaleY(0.7) rotate(0);
      }
    }
  }

  &.open {
    transform: translateX(80px);
    transition-delay: 0.2s;

    .menu-button {
      transform: rotate(90deg);
    }

    span {
      &:before {
        transform: translate(-50%, -50%) rotate(315deg);
        background-color: #fff;
      }

      &:after {
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: #fff;
      }
    }
  }
  

  @include mq('tablet', max){
    display: none;
  }
}