@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import "./_mixins.scss";

$bg-color: white;

body {
  font-family: "Poppins", sans-serif;
  background-color: $bg-color;
  overflow: hidden;
}
.cursor {
  position: fixed;
  left: 0;
  top: 0;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: black;
  pointer-events: none;
  mix-blend-mode: difference;
  background-color: white;
}
.main-container {
  display: flex;
  //align-items: center;
  align-items: center;
  flex-direction: column;
  width: calc(100vw - 50px);
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding-bottom: 10vh;

  h1 {
    width: 100%;
    font-size: clamp(100px, 12vw, 15vw);
    font-weight: bold;
    font-family: "Anton", sans-serif;
    color: #111;
    text-align: left;
    margin-top: 13vh;
  }

  @include mq("tablet", min) {
    margin-left: 75px;
    padding-left: 10px;
    border-left: 1px solid rgb(116, 116, 116);
    width: calc(100vw - 126px);
  }

  @include mq("phone", max) {
    h1 {
      font-size: clamp(85px, 12vw, 15vw);
    }
  }
  @include mq("tablet-small", max) {
    padding-top: 20vh;
  }


}

.absolute {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: black;
  z-index: 12;
}

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: "all 0.25s ease-in-out";
  background-color: #191919;
  color: #dbd8d6;
  h1 {
    font-size: 1.5vw;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }
  h2 {
    font-size: 1.5vw;

    text-transform: uppercase;
    font-family: "Poppins", serif;
    font-style: italic;
    margin-top: 10px;
  }
}
