@import "../../../styles/_mixins.scss";

.menu-holder {
  .menu-inside {
    position: fixed;
    width: 100vw;
    height: 100vh;
    transform: translateX(-100%);
    transition: transform 1s cubic-bezier(1, 0, 0, 1);
    will-change: transform;
    background-color: #111;
    color: white;
    z-index: 10;

    &.open {
      transform: translateX(0);

      .internal-nav-links {
        @for $i from 0 through 4 {
          li:nth-child(#{$i + 1}n) {
            list-style: none;

            a {
              transition-delay: #{$i/10 + 0.8}s;
              text-decoration: none;
              color: white;
              font-family: "Poppins", sans-serif;
              &:hover{
                font-weight: bolder;
              }
            }
          }
        }

        li {
          a {
            transform: translate(0);
            transition-duration: 0.8s;
            transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          }
        }
      }
      .external-nav-links {
        @for $i from 0 through 4 {
          li:nth-child(#{$i + 1}n) {
            list-style: none;
            a {
              transition-delay: #{$i/10 + 1}s;
              color: white;
            }
          }
        }

        li {
          a {
            opacity: 1;
            transform: translate(0);
            transition-duration: 1s;
            transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          }
        }
      }
    }

    .menu-nav-container {
      position: relative;
      left: 220px;
      top: 100px;
      overflow: hidden;
      width: calc(90vw - 260px);
    }

    .internal-nav-links {
      li {
        margin-bottom: 20px;
        position: relative;

        a{
          font-size: clamp(32px, 4vw, 52px);
          line-height: 1.1em;
          transform: translate(-100%);
          will-change: transform;
          transition: transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        
        img {
          pointer-events: none;
          aspect-ratio: 16/9;
          opacity: 0;
          display: block;
          width: 30vw;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) translateX(-30px);
          right: 10%;
          object-fit: cover;
          filter: brightness(1.3) grayscale(0.54) contrast(0.8) saturate(1.2) sepia(0.21);
          transition: transform 0.5s ease-in-out;
        }
      }
     
    }
    .external-nav-links {
      margin-top: 100px;

      display: flex;
      flex-wrap: wrap;
      align-items: center;

      li {
        margin-right: 30px;
        //cursor: pointer;

        a {
          transform: translate(-100%);
          opacity: 0;
          will-change: transform;
          transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
        &:last-of-type {
          margin-right: 0px;
        }
      }
    }
  }

  @include mq("tablet", max) {
    .menu-inside {
      .menu-nav-container {
        left: 10%;
        width: 100vw;

        img {
          display: none !important;
        }
      }
    }
  }
}
